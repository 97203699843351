import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Row, Col, Container } from "styled-bootstrap-grid"
import "./test.css"

const PostWrapper = styled.div`
  background-color: ${({
    theme: {
      palette: { darkblue },
    },
  }) => darkblue};
  padding-top: ${({
    theme: {
      header: { mobile },
    },
  }) => mobile};
  min-height: calc(
    100vh -
      ${({
        theme: {
          footer: { mobile },
        },
      }) => mobile}
  );
  color: white;

  @media only screen and (min-width: 576px) {
    padding-top: ${({
      theme: {
        header: { desktop },
      },
    }) => desktop};
    min-height: calc(
      100vh -
        ${({
          theme: {
            footer: { desktop },
          },
        }) => desktop}
    );
  }
`

const Content = styled.div`
  padding: ${({
    theme: {
      content: {
        padding: { mobile },
      },
    },
  }) => mobile};
  padding-bottom: ${({
    theme: {
      footer: { mobile },
    },
  }) => mobile};
  padding-top: 6rem;

  @media only screen and (min-width: 576px) and (max-width: 767px) {
    padding: ${({
      theme: {
        content: {
          padding: { tablet },
        },
      },
    }) => tablet};
    padding-bottom: ${({
      theme: {
        footer: { mobile },
      },
    }) => mobile};
  }

  @media only screen and (min-width: 768px) {
    padding: ${({
      theme: {
        content: {
          padding: { tablet },
        },
      },
    }) => tablet};
    padding-top: 1rem;
    padding-bottom: ${({
      theme: {
        footer: { mobile },
      },
    }) => mobile};
  }

  @media only screen and (min-width: 1900px) {
    padding: ${({
      theme: {
        content: {
          padding: { desktop },
        },
      },
    }) => desktop};
    padding-top: 5rem;
    padding-bottom: ${({
      theme: {
        footer: { mobile },
      },
    }) => mobile};
  }
`
const PostImage = styled.img`
  max-width: 100%;
`

const PostTitle = styled.div`
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s50};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w300};
  line-height: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s62};
  margin-bottom: 4rem;
`

const PostAuthor = styled.div`
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s28};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w300};
  line-height: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s36};
`

const PostContentWrapper = styled.div`
  margin-top: 7rem;
  line-height: 36px;
  font-weight: 300;

  & p {
    margin: 40px 0;
    padding: 0 15px;
  }
`

export default ({ data }) => {
  const post = data.allWordpressPost.edges[0].node
  const {
    featured_media: { source_url },
    title,
    id,
    content,
  } = post

  // console.log(post)
  return (
    <Layout>
      <PostWrapper key={id}>
        <Content>
          <Container fluid>
            <Row>
              <Col md={6}>
                <PostImage src={source_url} />
              </Col>
              <Col md={6}>
                <PostTitle>{title}</PostTitle>
                <PostAuthor>By Michael Allen</PostAuthor>
              </Col>
            </Row>
          </Container>
          <PostContentWrapper>
            <div className="post-5 post type-post status-publish format-standard has-post-thumbnail hentry category-a-total-bunch-of-bankers entry">
              <div
                className="entry-content"
                dangerouslySetInnerHTML={{ __html: content }}
              ></div>
            </div>
          </PostContentWrapper>
        </Content>
      </PostWrapper>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    allWordpressPost(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          content
          title
          id
          featured_media {
            source_url
          }
          author {
            name
          }
        }
      }
    }
  }
`
